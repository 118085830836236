import { Component, Input, OnInit, Inject, ɵɵqueryRefresh } from '@angular/core';
import { LibImanageAssistantService } from './lib-imanage-assistant.service';
import { IShell } from './models/ishell';
import { ShellList } from './models/shell-list';
import { AdalAuthService } from './adal-auth.service';
import { AssistantConfig } from './assistant.config';
import { ConfigService } from './config.service';
import { EngagementType } from './models/engagementType';


@Component({
    selector: 'limast-lib-imanage-assistant',
    templateUrl: 'lib-imanage-assistant.component.html',
    styleUrls: ['lib-imanage-assistant.component.css']
})
export class LibImanageAssistantComponent implements OnInit {
    public ERAStatus = true;
    public errorMessage = '';
    public currentSelectedShell: IShell;
    public ERAUrl = '';
    public ERAName = '';
    //public baseUrl = '';
    public id = '';  // can be shell or era id
    public selectedShellId = '';
    public budgets: Array<any>;
    public shells: IShell[] = [];
    public mainUrl: string;
    public mainName = '';
    public homeUrl: string;
    public shellName: string;
    public allFieldsSaved = true;  // may need to pre-set
    public opportunityURL: string;
    public opportunityName: string;
    public selectedEngagement = 0;
    public type: string;
    public accountName: string;
    public accountUrl: string;
    public insideiManage: boolean = true;  // set by default, so that blank shell in iManage, after save, will be correct
    //public showMyOpportunitiesEngagementsSection: boolean = true;  // default is to show
    //public showAccountClientSection: boolean = true;  // default is to show
    public showOpportunitySection: boolean = true;  // default is to show
    //public showERASection: boolean = true;  // default is to show
    public showBudgetsSection: boolean = true;  // default is to show, only used to hide if not 200/310
    public canUnlinkEra: boolean = true;
    public hasActivatedSubBudget: boolean;
    public BudgetId: string;
    public engagementStatus: string;
    public unlinkMsg: string;
    public redirectUrl: string;
    public canAccessBudgetTool: boolean = true;
    public scenario: string;
    public prompt: any;
    public canManage: boolean;
    isAuthenticated: boolean = false;
    public pkicViewRequestsUrl: string;
    public disableMsg: string = 'This object has not been created/linked through iManage Assistant.';
    public accountClientErrorMsg: string = 'This object has not been linked through iManage Assistant';  // this msg updated only if client.salesforceUrl = null
    public createErrorMessage = 'There was an error creating this object. Please contact iManage Support.';
    //public budgetError = false;
    //public mainInProgress = false;
    //public budgetNotCreated = false;
    public showCollaborationSection: boolean = true;
    public collaborationURL: string;
    public collaborationMessage: string;
    public isTeamsEnabled: boolean = false;
    public canCreateTeam: boolean = null;
  public teamStatus: number;
  public unlinkTeamMsg: string;
    public showPKICSection: boolean = false;
    public canCreatePKICRequest: boolean = false;
    public canCreateOpportunity: boolean = false;
    public engagementType: number = 0;//OpportunityLinked - 0, OpportunityCreated - 1
    public salesforceUrlPrefix: string = '';//'https://protiviti--AppTest.cs14.my.salesforce.com/006';
    public salesforceLightningUrl: string = '';
    public pkicErrorMsg = "Shell is incomplete.";
    public changeOrderOpportunites: Array<any>;
    public chageOrderFormUrl: string;
    public canCreateMain: boolean = false;
    public mainStatus: number = 0;
    public powerBIUrl: string = '';
    public drbStatus: string = null;
    public drbUrl: string = '';
    public opportunityStatus: number = 0;
    public showDRBSection: boolean = false;
    public proposalDocuments: Array<any>;
    public blob: any;
    public disableMainBtn: boolean = false;
    public showClientAcceptanceSection: boolean = false;
    public hasOneToManyLinkedTeamSite: boolean = false;
    public isConfidential: boolean = false;
    public showResourceRequest: boolean = false;
    public canCreateResourceReq: boolean = false;
    public resReqUrl: string = '';
    public contractsUrl: string = '';
    public showContractSection: boolean = false;
    public isTalentManager: boolean = false;
    showClientAcceptanceButton: boolean = false;
    clientAcceptanceWorkflows: Array<any> = [];
    clientAcceptanceUrl = '';
    isClientAcceptanceApprovalRequired: boolean;

    constructor(private assistantService: LibImanageAssistantService, private adalAuthService: AdalAuthService,
        @Inject('assistantConfig') private config: AssistantConfig, private configService: ConfigService) {

        //assistant embedded in iManage - use config from imanage configuration service
        if (!this.config.isApp) {
          this.configService.getConfig().subscribe(x => {
            this.config.iManageUrl = x.iManageUrl;
            this.config.iManageApiUrl = x.iManageApiUrl;
            this.config.iBudgetUrl = x.iBudgetUrl;
            this.config.pkicViewRequestsUrl = x.pkicViewRequestsUrl;
            this.config.teamsUrl = x.teamsUrl;
            this.ERAUrl = x.eraUrl;
            this.salesforceUrlPrefix = x.validUrl;
            this.salesforceLightningUrl = x.salesforceLightningUrl;
            this.powerBIUrl = x.powerBIUrl;
          });
        }
        else {
          this.salesforceUrlPrefix = this.config.salesforceUrl;
          this.salesforceLightningUrl = this.config.salesforceLightningUrl;
          this.powerBIUrl = this.config.powerBIUrl;
        }
        //this.chageOrderFormUrl = this.config.iManageUrl + '/engagements/' + this.shells[this.selectedEngagement].id + '/orderOpportunityForm/';
        console.log('configuration:');
        console.log(this.config);
    }

    isLoaded: boolean = true;
    ngOnInit() {
        this.isLoaded = false;
        setTimeout(() => {
            this.isLoaded = true;
        }, 500);
        console.log('userAgent:');
        console.log(window.navigator.userAgent);
        if (this.config.isApp) {
            this.adalAuthService.handlecallback();
            if (this.adalAuthService.isUserAuthenticated()) {
                this.isAuthenticated = true;
            } 
            else {//this.adalAuthService.login();
            }
        }
        this.pkicViewRequestsUrl = this.config.pkicViewRequestsUrl;

        this.loadAssistant();
    }

    checkAuthentication() {
        if (this.config.isApp && !this.adalAuthService.isUserAuthenticated()) {
            this.adalAuthService.login();
        }
    }

    loadAssistant() {
        if (this.config.isApp) {//inside iFrame
            this.id = this.getParameterByName("id", null);
            this.type = this.getParameterByName("type", null);
            this.redirectUrl = this.getParameterByName("redirectUrl", null);
            if (this.id && this.id != "null") {
                this.selectedEngagement = 0;
                this.insideiManage = false;
                this.assistantService.getShellsAssistant(this.id, this.type)
                .subscribe(
                (list: ShellList) => {
                    this.canAccessBudgetTool = list.canAccessBudgetTool;
                    this.opportunityURL = list.opportunityUrl;
                    this.accountUrl = list.accountUrl;
                    this.shells = list.shells;
                    if (this.shells && this.shells.length) {
                        this.hasOneToManyLinkedTeamSite = this.shells[this.selectedEngagement].hasOneToManyLinkedTeamSite;
                        this.isConfidential = this.shells[this.selectedEngagement].isConfidential;
                    }
                    //this.loaded = true;
                    this.setupAssistant(this.id);
                    console.log(this.allFieldsSaved);
                  },
                  (error: any) => { this.errorMessage = <any>error; console.log(error); }
                );
            }
            else{
                this.setupAssistant(null);
            }
        } else {//inside iManage
            this.insideiManage = true;
            this.type = 'embedded';
            if (this.shells && this.shells.length) {
                this.hasOneToManyLinkedTeamSite = this.shells[this.selectedEngagement].hasOneToManyLinkedTeamSite;
                this.isConfidential = this.shells[this.selectedEngagement].isConfidential;
            }
            this.setupAssistant(this.id);
            this.assistantService.allFieldsSavedChange.subscribe((allFieldsSaved) => {
                this.allFieldsSaved = allFieldsSaved;
            });
            console.log("in assistant", this.allFieldsSaved);
        }
    }

    //Linked Shell - 1 Shell - Scenario 1
    //Linked Shell - multiple Shells - Scenario 2
    //Linked Shell - Not All Fields are saved - Scenario 0.5
    //Not a linked Shell (Assistant on Apps other than Shell) - scenario 0
    setupAssistant(id: string) {
        //if (this.type == 'embedded' && window.location.href.indexOf('/orderOpportunityForm/') < 0) {
        //  this.showDRBSection = true;
        //}

          if (id && this.shells[this.selectedEngagement]) {
              if (this.shells.length == 1) {
                  this.scenario = "1";
              } else {
                  this.scenario = "2";
           }

            this.isTalentManager = (this.shells[this.selectedEngagement].userRole == "TalentManager");

            this.assistantService.currentShell = this.shells[this.selectedEngagement];
            this.selectedShellId = this.shells[this.selectedEngagement].id;
            this.currentSelectedShell = this.shells[this.selectedEngagement];

            this.allFieldsSaved = this.shells[this.selectedEngagement].allFieldsSaved;
            this.isTeamsEnabled = this.shells[this.selectedEngagement].isTeamsEnabled;
            this.canCreateTeam = this.shells[this.selectedEngagement].teamStatus === 0 && this.isTeamsEnabled;
            this.teamStatus = this.shells[this.selectedEngagement].teamStatus;
            this.showPKICSection = true;
            this.canCreatePKICRequest = this.shells[this.selectedEngagement].canCreatePKICRequest;
          this.canCreateOpportunity = (this.shells[this.selectedEngagement].canCreateOpportunity
            && this.shells[this.selectedEngagement].canManage
            && this.shells[this.selectedEngagement].status != "Closed");
              //&& this.shells[this.selectedEngagement].allFieldsSaved);
            this.engagementType = this.shells[this.selectedEngagement].type;
          this.chageOrderFormUrl = this.config.iManageUrl + 'engagements/' + this.shells[this.selectedEngagement].id + '/orderOpportunityForm/';
          this.drbUrl = this.shells[this.selectedEngagement].DRBURL;
          this.drbStatus = this.shells[this.selectedEngagement].drbStatus;
          if (this.currentSelectedShell.opportunityStageId === 'e2446e2e-82aa-4afa-bed7-1d7288d289b3' || this.currentSelectedShell.opportunityStageId === 'f3a97bab-7823-499d-95b3-93f311613f39' || this.currentSelectedShell.opportunityStageId === '669b4ecc-bf33-47bb-9496-df8c8ad52441') {
            this.disableMainBtn = true;
          }

            this.hasOneToManyLinkedTeamSite = this.shells[this.selectedEngagement].hasOneToManyLinkedTeamSite;


            if (!this.allFieldsSaved) {
                this.scenario = "0.5";
            }
            //this.populateHome();
            this.populateEngagements();
            this.populateAccounts();
            this.populateOpportunity();
            this.populateERA();
            this.populateBudgets();
            this.populateCollaboration();
            this.loadProposals(this.selectedShellId);
            this.populateResourceReq();
            this.populateContracts();
            this.loadClientAcceptance();

        } else if (id && this.shells.length == 0) {
            // if id is blank, which means it's a main budget with no shell associated, or stand alone sub-budget
            this.scenario = "0";

            //if (!this.shells[this.selectedEngagement].accountKey) {
            //  this.accountName = "No Salesforce account linked or associated with selected client record.";
            //  this.accountUrl = null;
            //}
            //else
            if (this.accountUrl == null || this.accountUrl == '') {  // client should always exist, even for draft ERAs with no shell
              if (this.type == "era") {
                  this.accountName = "No Salesforce account linked or associated with selected client record.";
              }
              else {
                  this.accountName = this.disableMsg;
              }
            } else {
                this.accountName = 'Salesforce Account';
            }

            if (this.opportunityURL == null || this.opportunityURL == '') {
                this.opportunityName = this.disableMsg;
            }
            else {
                this.opportunityName = 'Original Salesforce Opportunity';
            }
            //this.populateHome();
            this.populateEngagements();
            this.populateAccounts();
            //this.populateOpportunity();
            this.populateERA();
            this.populateBudgets();
            this.populateCollaboration();
            this.loadProposals(id);
            this.populateResourceReq();
            this.populateContracts();
            this.loadClientAcceptance();
        } else {
            //this.showMyOpportunitiesEngagementsSection = true;

            //this.showAccountClientSection = true;
            this.accountName = this.disableMsg;
            this.accountUrl = '';

            this.showOpportunitySection = true;
            this.opportunityName = this.disableMsg;
            this.opportunityURL = '';

            //this.showERASection = false;
            this.ERAName = this.disableMsg;
            this.ERAUrl = '';

          if (this.type == "era" || this.type == "salesforce") {
            this.showBudgetsSection = false;
          }
          else {
            this.showBudgetsSection = true;
            this.mainName = this.disableMsg;
            this.mainUrl = '';

          }
          

            this.showCollaborationSection = false;
            //this.collaborationMessage = this.disableMsg;
            this.collaborationURL = null;

            console.log(this.shells);
            this.assistantService.assistantChange.next(this.shells);
            console.log("this is when we have no data");
        }
    }

    refreshAssisant(element?: any) {
        console.log('inside refreshAssistant');
        if(this.config.isApp){
            console.log("inside app condition");
            if(!this.adalAuthService.isUserAuthenticated()){
                this.adalAuthService.login();
            }
            else{
                window.location.reload();
            }
        }
        else{
            this.id = this.getParameterByName("id", null);
            this.type = this.getParameterByName("type", null);
            this.redirectUrl = this.getParameterByName("redirectUrl", null);

            if (this.id) {
                this.selectedEngagement = 0;
                this.insideiManage = false;
                this.assistantService.getShellsAssistant(this.id, this.type)
                    .subscribe(
                    (list: ShellList) => {
                        console.log('shell:');
                        console.log(list);
                        this.canAccessBudgetTool = list.canAccessBudgetTool;
                        this.shells = list.shells;
                        //this.loaded = true;
                        this.setupAssistant(this.id);
                    },
                  (error: any) => { this.errorMessage = <any>error; console.log(error); }
                    );
            }
            else {
                if (this.shells != null && this.shells.length == 1) {
                    var sid = this.shells[0].id;
                    this.assistantService.getShellsAssistant(sid, "embedded")
                        .subscribe(
                        (list: ShellList) => {
                            console.log('shell:');
                            console.log(list);
                            this.canAccessBudgetTool = list.canAccessBudgetTool;
                            this.opportunityURL = list.opportunityUrl;
                            this.accountUrl = list.accountUrl;
                            this.shells = list.shells;
                            //this.loaded = true;
                            this.setupAssistant(sid);
                        },
                      (error: any) => { this.errorMessage = <any>error; console.log(error); }
                        );
                }
                this.assistantService.assistantChange.next(this.shells)
                //this.setupAssistant(this.id);
          }
          if (element != null) {
            element.src = '../assets/icons/reloadGray-35x35.png';
          }
            
        }
    }

    //populateHome() {
    //    this.homeUrl = this.config.iManageUrl + 'engagements/shellEdit/' + this.id;
    //}
    populateEngagements() {
        //this.showMyOpportunitiesEngagementsSection = true;
    }
    populateAccounts() {
      //this.showAccountClientSection = true;
      if (this.scenario != "0") {

        if (!this.shells[this.selectedEngagement].clientId) {
          this.accountName = 'No Account/Client record selected.';
          this.accountUrl = '';
        }
        else if (this.shells[this.selectedEngagement].clientNameSalesForceUrl && this.shells[this.selectedEngagement].accountKey) {
            this.accountName = 'Salesforce Account';
            this.accountUrl = this.shells[this.selectedEngagement].clientNameSalesForceUrl;
        } else if (this.shells[this.selectedEngagement].clientId && !this.shells[this.selectedEngagement].accountKey) {
            this.accountName = 'No Salesforce Account linked or associated with selected client record.';
            this.accountUrl = '';
        }
        else {
            this.accountName = this.disableMsg;
            this.accountUrl = '';
        }
      }
  }

  
    populateOpportunity() {
      this.showOpportunitySection = true;
      if (this.scenario !== "0") {
        if (this.engagementType == 0) {
          this.changeOrderOpportunites = [];
          if (this.shells[this.selectedEngagement].opportunityURL && (this.shells[this.selectedEngagement].opportunityURL.toLowerCase().startsWith(this.salesforceUrlPrefix.toLowerCase() + '006') || this.shells[this.selectedEngagement].opportunityURL.toLowerCase().startsWith(this.salesforceLightningUrl.toLowerCase() + 'r/opportunity/006'))
            && this.shells[this.selectedEngagement].opportunityURL.length > 0) {
            this.opportunityName = 'Salesforce Opportunity';
            this.opportunityURL = this.shells[this.selectedEngagement].opportunityURL;

            this.assistantService.getChangeOrderOpprs(this.shells[this.selectedEngagement].id).subscribe(res => {
              this.changeOrderOpportunites = res;
              if (this.changeOrderOpportunites.length > 0) {
                this.changeOrderOpportunites.sort(function (a, b) {
                  var c = new Date(a.createdDate).getTime();
                  var d = new Date(b.createdDate).getTime();
                  return c > d ? 1 : -1;
                });
              }
            });
              //if (this.shells[this.selectedEngagement].changeorderopportunities.length > 0) {
              //    this.changeOrderOpportunites = this.shells[this.selectedEngagement].changeorderopportunities;
              //    this.changeOrderOpportunites.sort(function (a, b) {
              //        var c = new Date(a.createdDate).getTime();
              //        var d = new Date(b.createdDate).getTime();
              //        return c > d ? 1 : -1;
              //    });
              //}
          }
          else {
            this.opportunityName = 'No Salesforce Opportunity has been linked.';
            this.opportunityURL = '';
          }
        } else { //engagementType = 1\
          this.opportunityStatus = this.shells[this.selectedEngagement].opportunityStatus;
          if (this.shells[this.selectedEngagement].opportunityURL && this.shells[this.selectedEngagement].opportunityURL.length > 0) {
            this.opportunityName = 'Original Salesforce Opportunity';
            this.opportunityURL = this.shells[this.selectedEngagement].opportunityURL;

            if (this.engagementType == EngagementType.OpportunityCreated) {
              this.changeOrderOpportunites = [];
              this.assistantService.getChangeOrderOpprs(this.shells[this.selectedEngagement].id).subscribe(res => {
                this.changeOrderOpportunites = res;
                if (this.changeOrderOpportunites.length > 0) {
                  this.changeOrderOpportunites.sort(function (a, b) {
                    var c = new Date(a.createdDate).getTime();
                    var d = new Date(b.createdDate).getTime();
                    return c > d ? 1 : -1;
                  });
                }
              });
              //this.changeOrderOpportunites = this.shells[this.selectedEngagement].changeorderopportunities;
              //this.changeOrderOpportunites.sort(function (a, b) {
              //  var c = new Date(a.createdDate).getTime();
              //  var d = new Date(b.createdDate).getTime();
              //  return c > d ? 1 : -1;
              //});
            }
          }
          else if (this.shells[this.selectedEngagement].opportunityStatus == 1) {
            this.opportunityName = "Salesforce Opportunity creation in progress.";
            this.opportunityURL = '';
          }
          else if (this.shells[this.selectedEngagement].opportunityStatus == 2) {
            this.opportunityName = "There was an error creating this object. Please contact iManage support.";
            this.opportunityURL = '';
          }
          else {
            this.opportunityName = 'Shell is incomplete; Salesforce Opportunity has not been created.';
            this.opportunityURL = '';
          }
          
        }
      }
    }
    

    //populateOpportunity() {
    //    if (this.scenario == "0") {
    //        this.showOpportunitySection = true;
    //    } else {
    //      this.showOpportunitySection = true;
    //      if (this.shells[this.selectedEngagement].opportunityURL && (this.shells[this.selectedEngagement].opportunityURL.toLowerCase().startsWith(this.salesforceUrlPrefix.toLowerCase() + '006') || this.shells[this.selectedEngagement].opportunityURL.toLowerCase().startsWith(this.salesforceLightningUrl.toLowerCase() + 'r/Opportunity/006'))
    //        && this.shells[this.selectedEngagement].opportunityURL.length > 0) {
    //        this.opportunityName = 'Salesforce Opportunity';
    //        this.opportunityURL = this.shells[this.selectedEngagement].opportunityURL;
    //      }
    //      else {
    //        if (this.engagementType == 1) {
    //          if (this.shells[this.selectedEngagement].opportunityStatus == 1) {
    //            this.opportunityName = "Salesforce Opportunity creation in progress.";
    //          }
    //          else if (this.shells[this.selectedEngagement].opportunityStatus == 2) {
    //            this.opportunityName = "There was an error in creating this object. Please contact iManage support.";
    //          }
    //          else {
    //            this.opportunityName = 'Shell is incomplete; Salesforce opportunity has not been created.';
    //          }
    //        }
    //        else {
    //          this.opportunityName = 'No Salesforce opportunity has been linked for this engagement.';
    //        }
    //        this.opportunityURL = '';
    //      }
    //      if (this.engagementType == EngagementType.OpportunityCreated) {
    //        this.changeOrderOpportunites = [];
    //        this.changeOrderOpportunites = this.shells[this.selectedEngagement].changeorderopportunities; 
    //      }
            
    //    }
    //}

  createOpportunity() {
    if (this.canCreateOpportunity && this.allFieldsSaved && (this.currentSelectedShell.opportunityStageId == '9141e894-f3fa-481f-9a33-35cb02f31049' || this.engagementType == 0)) {
      window.open(this.config.iManageUrl + 'engagements/' + this.shells[this.selectedEngagement].id + '/orderOpportunityForm/0', '_top');
    }
  }
  
  createOpportunityHover(element: any) {
    
    if (this.canCreateOpportunity && this.allFieldsSaved && (this.currentSelectedShell.opportunityStageId == '9141e894-f3fa-481f-9a33-35cb02f31049' || this.engagementType == 0)) {
      const elemImg: Element = document.getElementById('imgCreateOpportunity');
      elemImg.setAttribute('src', '/assets/icons/New-Teal.png');
      (elemImg as HTMLElement).style.cursor = 'pointer';
      element.style.opacity = '1.0';
    }
    
  }

  createOpportunityUnhover(element: any) {
    if (this.canCreateOpportunity && this.allFieldsSaved) {
      element.src = '/assets/icons/New-Grey.png';
    }
  }

    
    selectOpportunityEngagement(i: number, element?: any) {
        this.selectedEngagement = i;
        if (this.shells && this.shells[this.selectedEngagement] && this.shells[this.selectedEngagement].budgets) {
            this.setupAssistant(this.shells[this.selectedEngagement].id);
        }
    }

    getParameterByName(name: string, url: string) {
        if (!url) url = window.location.href;
        console.log('getParameterByName url ' + url);
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    changeHeight(): any {
        let height = this.shells.length == 1 ? 35 : this.shells.length * 25;  // if only 1 shell, set 35 to prevent y scroll bar from showing
        return {'font-size': '16px'};
    }

    
    
    goShellEditPage(url: string) {
      if (this.engagementStatus != 'Closed' && this.canManage) {
            //console.log('goShellEditPage ' + url);
            //this.shellService.goShellEditPage(url);
          window.open(this.config.iManageUrl + 'engagements/shellEdit/' + url ? url : this.selectedShellId, '_top');
        } else {
            return false;
        }
    }

    goShellHome() {
        window.open(this.config.iManageUrl, '_top');
    }

    selectOpportunityEngagementHover(element: any) {
      element.src = '/assets/icons/Filter-Teal.png';
    }

    selectOpportunityEngagementUnhover(element: any) {
      element.src = '/assets/icons/Filter-Grey.png';
    }

    filterIconHover(element: any) {
      element.src = '/assets/icons/Link-Teal.png';
    }

    filterIconUnhover(element: any) {
      element.src = '/assets/icons/Link-Grey.png';
    }

    selectedOpportunityHover(element: any) {
      element.src = '/assets/icons/Filter-Teal.png';
    }

    selectedOpportunityUnhover(element: any) {
        if (element.id != (this.selectedEngagement + 'opp')) {
          element.src = '/assets/icons/Filter-Grey.png';
        }
        // console.log('id ' + element.id);
    }

    refreshHover(element: any) {
        element.src = '/assets/icons/reloadBlue-35x35.png';
    }

    refreshUnhover(element: any) {
      element.src = '/assets/icons/reloadGray-35x35.png';
    }


    //Start - ERA Section
    populateERA() {
        this.unlinkMsg = 'Unlink';
        if (this.shells[this.selectedEngagement]) {
            this.canManage = this.shells[this.selectedEngagement].canManage;
          if (!this.shells[this.selectedEngagement].canUnlinkEra) {
            if (this.shells[this.selectedEngagement].userRole == "FinanceUser" && this.shells[this.selectedEngagement].isConfidential) {
                this.unlinkMsg = ''
              }
              else {
                this.unlinkMsg = 'ERAs cannot be removed after budget submission has occurred.'
              }
            }
            if (!this.shells[this.selectedEngagement].canManage || this.shells[this.selectedEngagement].status == "Closed") {
                this.unlinkMsg = ''
            }
        }
        if (this.scenario == "0") {
            //this.showERASection = true;
            this.ERAName = this.disableMsg;
            this.ERAUrl = '';
        }
        else if (!(this.shells[this.selectedEngagement].riskAssessment || this.shells[this.selectedEngagement].riskAssessmentUrl)){//if ERA is not linked
            if (this.scenario == "0.5") {//if shell is incomplete
                this.canUnlinkEra = this.shells[this.selectedEngagement].canUnlinkEra;
                //this.showERASection = true;
                this.ERAName = "Shell is incomplete; ERA has not been created/linked."; //this.disableMsg;
                this.ERAUrl = '';
                this.ERAStatus = true;
            }
            else{//if shell is complete
                //this.showERASection = true;
                this.ERAStatus = true; 
            }
        }
        else {
            this.canUnlinkEra = this.shells[this.selectedEngagement].canUnlinkEra;
            //this.showERASection = true;
          if (this.shells[this.selectedEngagement].riskAssessment || this.shells[this.selectedEngagement].riskAssessmentUrl) {
                this.ERAStatus = false;  // has ERA
                this.ERAName = (this.shells[this.selectedEngagement].riskAssessment && this.shells[this.selectedEngagement].riskAssessment.name) ? this.shells[this.selectedEngagement].riskAssessment.name : 'Salesforce ERA';
                this.ERAUrl = this.shells[this.selectedEngagement].riskAssessmentUrl.replace("/erasetup/", "?erasetup=1&id=");
            }
            else {
                this.ERAStatus = true; // no ERA
            }
        }
    }

    createERA() {
        if (!this.canManage) {
            return false;
        }

        if (this.hasActivatedSubBudget) {
            return false;
        }
        this.ERAStatus = false;
        this.assistantService.createERA();
    }

  createDRB() {
    if (this.canManage) {
      this.assistantService.createDRB(this.shells[this.selectedEngagement])
        .subscribe(
          result => {
            this.drbStatus = result.drbStatus;
          });
    }
        

      //var that = this;

      //this.prompt = {
      //  notify: function (msg: any) {
      //    this.msg = "";
      //    if (msg == "ok") {
      //      that.assistantService.createDRB(that.shells[that.selectedEngagement])
      //        .subscribe(
      //          result => {
      //            that.drbStatus = result.drbStatus;
      //          })
      //    }
      //  }, msg: "Click OK to create DRB or click Cancel."
      //}
    }

    linkERA() {
        if (!this.canManage || this.hasActivatedSubBudget) {
            return false;
        }
        else{
            if (this.redirectUrl) {
                window.open(this.config.iManageUrl + 'engagements/linkfilter?shellId=' + this.selectedShellId + '&redirectUrl=' + encodeURIComponent(this.redirectUrl), '_top');
            }
            else {  // inside iManage
                window.open(this.config.iManageUrl + 'engagements/linkfilter?shellId=' + this.selectedShellId, '_top');
            }  
        }
    }
    
    unlinkERA() {
        if (this.hasActivatedSubBudget || !this.canUnlinkEra) {
            return false;
        }
        var that = this;
        this.prompt = {
            notify: function (msg: any) {
                this.msg = "";
                if (msg == "ok") {
                    // call api/Engagements PATCH to remove ERA, set riskAssessmen = null
                    that.assistantService.unlinkERA(that.selectedShellId)
                        .subscribe(
                        result => {
                            if (!that.insideiManage) {
                                that.id = that.getParameterByName("id", null);
                                that.type = that.getParameterByName("type", null);
                                var eraname = that.getParameterByName("name", null);
                                that.redirectUrl = that.getParameterByName("redirectUrl", null);

                                if (eraname == "erasetup") {
                                    that.assistantService.goEra(that.id, eraname);
                                }
                                else if (that.id) {
                                    // iFrame
                                    that.selectedEngagement = 0;
                                    that.insideiManage = false;
                                    that.assistantService.getShellsAssistant(that.id, that.type)
                                        .subscribe(
                                        (list: ShellList) => {
                                            that.canAccessBudgetTool = list.canAccessBudgetTool;
                                            that.shells = list.shells;
                                            //that.loaded = true;
                                            that.setupAssistant(that.id);
                                        },
                                        (error: any) => { that.errorMessage = <any>error; console.log(error); }
                                        );
                                }
                                else {
                                    that.setupAssistant(that.id);
                                    // console.log('540 shell id blank');
                                    that.assistantService.allFieldsSavedChange.subscribe((allFieldsSaved) => {
                                        that.allFieldsSaved = allFieldsSaved;
                                    });

                                    // used by iManage only, not iFrame
                                    that.assistantService.assistantChange.subscribe((shells: IShell[]) => {
                                        that.insideiManage = true;
                                        that.shells = shells;
                                        that.selectedShellId = shells[0].id;
                                        that.allFieldsSaved = that.shells[0].allFieldsSaved;
                                        that.setupAssistant(shells[0].id);
                                        console.log('519 shells  ');
                                    });
                                }
                            } else {
                              that.assistantService.goShell(that.selectedShellId, that.engagementType);
                              that.ERAStatus = true;  // inside iManage
                            }
                        },
                        error => that.errorMessage = <any>error
                        );
                }
            }, msg: "Click OK to unlink or click Cancel."
        }
    }

    unlinkERAHover(element: any) {
        if (this.shells[this.selectedEngagement].canUnlinkEra) {
          element.src = '/assets/icons/Unlink-Teal.png';
        }
    }

    unlinkERAUnhover(element: any) {
      element.src = '/assets/icons/Unlink-Grey.png';
    }

    linkERAHover(element: any) {
        if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage)
          element.src = '/assets/icons/Link-Teal.png';
    }

    linkERAUnhover(element: any) {
      element.src = '/assets/icons/Link-Grey.png';
    }

    createERAHover(element: any) {
        if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage)
          element.src = '/assets/icons/New-Teal.png';
    }

    createERAUnhover(element: any) {
      element.src = '/assets/icons/New-Grey.png';
    }

    //End - ERA Section


    //Start - Budget Section

  
    populateBudgets() {
      if (this.scenario == "0") {
        if (this.type == "era" || this.type == "salesforce") {
            this.showBudgetsSection = false;
          }
          else {
            this.showBudgetsSection = this.canAccessBudgetTool;
            this.mainUrl = '';
            this.mainName = this.disableMsg;
          }
        } else {
            this.budgets = [];
            this.assistantService.getBudgets(this.shells[this.selectedEngagement].id).subscribe(res => {
              this.budgets = res;
            });
            //this.budgets = this.shells[this.selectedEngagement].budgets; 
            this.hasActivatedSubBudget = this.shells[this.selectedEngagement].hasActivatedSubBudget;
            this.engagementStatus = this.shells[this.selectedEngagement].status;
            this.showBudgetsSection = this.shells[this.selectedEngagement].isInBudgetTool;
            //this.budgetError = this.shells[this.selectedEngagement].budgetStatus == 2;
            //this.mainInProgress = this.shells[this.selectedEngagement].budgetStatus == 1;
            //this.budgetNotCreated = this.shells[this.selectedEngagement].budgetStatus == 0;
            this.mainStatus = this.shells[this.selectedEngagement].budgetStatus;// 0 - Not Created, 1 - Creation In progress, 2 - Error in Creation, 3 - Created.
            this.mainUrl = this.config.iBudgetUrl + 'basicmainprojInfo/index?ContractId=' + this.shells[this.selectedEngagement].id + '&fromAssistant';
            this.mainName = this.shells[this.selectedEngagement].name;
            this.canCreateMain = this.shells[this.selectedEngagement].canCreateMain;
        }
    }

  copyBudget() {
    if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage)
      window.open(this.config.iBudgetUrl + 'CreateCloneBudget?imanagecopybudget&fromAssistant&ContractId=' + this.shells[this.selectedEngagement].id, '_top'); 
    else
      return false;
  }

    linkExistingBudget() {
      if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage)
        window.open(this.config.iBudgetUrl + 'addbudgetstomain/index?&fromAssistant&ContractId=' + this.shells[this.selectedEngagement].id, '_top');  
        else
            return false;
    }

    createBudget() {
      if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage)
          window.open(this.config.iBudgetUrl + 'CreateCloneBudget?imanagecreatebudget&fromAssistant&ContractId=' + this.shells[this.selectedEngagement].id, '_top');
      else
          return false;
    }

    createBudgetHover(element: any) {
      if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage) {
          element.src = '/assets/icons/New-Teal.png';
        }
    }

    createBudgetUnhover(element: any) {
      if (this.canManage) {
        element.src = '/assets/icons/New-Grey.png';
      }
    }

    copyBudgetHover(element: any) {
      if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage) {
          element.src = '/assets/icons/Copy-Teal.png';
        }
    }

    copyBudgetUnhover(element: any) {
      if (this.canManage) {
        element.src = '/assets/icons/Copy-Grey.png';
      }
    }

    linkExistingBudgetHover(element: any) {
      if (this.engagementStatus != 'Closed' && this.allFieldsSaved && this.canManage) {
          element.src = '/assets/icons/Link-Teal.png';
        }
    }

    linkExistingBudgetUnhover(element: any) {
      if (this.canManage) {
        element.src = '/assets/icons/Link-Grey.png';
      }
    }

    unlinkExistingBudgetHover(element: any) {
      if (this.engagementStatus != 'Closed' && this.canManage) {
          element.src = '/assets/icons/Unlink-Teal.png';
        }
    }

    unlinkExistingBudgetUnhover(element: any) {
      if (this.canManage) {
        element.src = '/assets/icons/Unlink-Grey.png';
      }
    }

  createMain() {
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage
      && this.currentSelectedShell.opportunityStageId !== 'e2446e2e-82aa-4afa-bed7-1d7288d289b3'
      && this.currentSelectedShell.opportunityStageId !== 'f3a97bab-7823-499d-95b3-93f311613f39'
      && this.currentSelectedShell.opportunityStageId !== '669b4ecc-bf33-47bb-9496-df8c8ad52441') {//closed-won oppr stage id
      this.assistantService.createMain(this.selectedShellId)
        .subscribe(() => {
          this.refreshAssisant();
        },
          (error: any) => {
            console.log('main creation: failed.');
            console.log(error);
            this.errorMessage = <any>error;
        });
    }
  }

  createMainHover(element: any) {
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage) {
      //if oppr stage is closed lost/declined/unqualified
      if (this.currentSelectedShell.opportunityStageId === 'e2446e2e-82aa-4afa-bed7-1d7288d289b3' || this.currentSelectedShell.opportunityStageId === 'f3a97bab-7823-499d-95b3-93f311613f39' || this.currentSelectedShell.opportunityStageId === '669b4ecc-bf33-47bb-9496-df8c8ad52441') {
        (element as HTMLElement).style.opacity = '0.2';
        (element as HTMLElement).title = "Opportunity Stage is Lost, Main Budget cannot be created.";
      }
      else {
        element.src = '/assets/icons/New-Teal.png';
        (element as HTMLElement).title = "";
      }
    }
  }

  createMainUnhover(element: any) {
      if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage) {
        element.src = '/assets/icons/New-Grey.png';
      }
    }

    //End - Budget Section

    //Start - Colloboration

  populateCollaboration() {
    this.unlinkTeamMsg = 'Unlink';
    this.collaborationURL = '';
    if (this.scenario == "0") {
      this.showCollaborationSection = this.isTeamsEnabled;
      this.collaborationMessage = this.disableMsg;
      this.collaborationURL = '';
    }
    else {
      this.showCollaborationSection = this.isTeamsEnabled;
      this.collaborationMessage = this.disableMsg;
      //if (!this.isAllRequiredFieldsPopulatedForTeams()) {
      //    this.collaborationMessage = 'No Teams Site has been created for this engagement.';
      //    this.collaborationURL = null;
      //    this.canCreateTeam = false;
      //} else
      if (this.shells[this.selectedEngagement].teamStatus == 3) {
        this.collaborationMessage = '';
        this.collaborationURL = this.shells[this.selectedEngagement].teamUrl;
      } else if (this.shells[this.selectedEngagement].teamStatus == 2) {
        this.collaborationMessage = 'There was an error creating this object. Please contact iManage Support.';
        this.collaborationURL = null;
      } else if (this.shells[this.selectedEngagement].teamStatus == 1) {
        this.collaborationMessage = 'Teams Site creation in progress.';
        this.collaborationURL = null;
      } else if (this.shells[this.selectedEngagement].teamStatus == 0) {
        if (!this.allFieldsSaved) {
          this.collaborationMessage = 'Shell is incomplete; Teams Site has not been created.';
          this.collaborationURL = null;
        }
        else if (!this.canCreateTeam) {
          this.collaborationMessage = 'No Teams Site has been created for this engagement.';
          this.collaborationURL = null;
        }
      }
    }
  }


  createTeam() {
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage) {
      // open popUp to create or link team
      let teamSiteUrl = this.config.iManageUrl + 'engagements/shellEdit/' + this.selectedShellId + '?source=assistant&isOpenTeamsCollab=1';
      if (this.config.isApp) { //inside iframe
       window.top.location.href = teamSiteUrl + '&navtype=redirect';
      }
      else{
        window.top.location.href = teamSiteUrl + '&navtype=popup';
      }

      // this.refreshAssisant();
      // this.assistantService.createTeamSite(this.selectedShellId)
      // .subscribe(() => {
      //     console.log('createTeam: success.');
      //     this.refreshAssisant();
      // },
      // (error: any) => {
      //     console.log('createTeam: failed.' + error);
      //     this.errorMessage = <any>error;
      // });
    }
  }

  createTeamHover() {
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage) {
      const elemImg: Element = document.getElementById('imgCreateTeam');
      elemImg.setAttribute('src', '/assets/icons/Plus-Link Teal.png');


      const elemSpan: Element = document.getElementById('spanCreateTeam');
      (elemSpan as HTMLElement).style.color = '#fff';

      (elemImg as HTMLElement).style.cursor = 'pointer';
      (elemSpan as HTMLElement).style.cursor = 'pointer';
    }
  }


  createTeamUnhover() {
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage) {
      const elemImg: Element = document.getElementById('imgCreateTeam');
      elemImg.setAttribute('src', '/assets/icons/Plus-Link Gray.png');


      const elemSpan: Element = document.getElementById('spanCreateTeam');
      (elemSpan as HTMLElement).style.color = '#9d9d9d';
    }
  }

  unlinkTeam() {
    // if (this.hasActivatedSubBudget || !this.canUnlinkEra) {
    //     return false;
    // }
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage) {
    var that = this;
    this.prompt = {
      notify: function (msg: any) {
        this.msg = "";
        if (msg == "ok") {
          // call api/Engagements PATCH to remove TeamSite, set teamSiteKey = null
          that.assistantService.unlinkTeamSite(that.selectedShellId)
            .subscribe(
              result => {
                if (result) {
                  // refresh assistant
                  window.location.reload();
                  that.refreshAssisant();
                }
               
              },
              error => that.errorMessage = <any>error
            );
        }
      }, msg: "Click OK to unlink or click Cancel."
    }
  }
  }

  unlinkTeamHover() {
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage){
    const elemUnlinkImg: Element = document.getElementById('imgUnlinkTeam');
      elemUnlinkImg.setAttribute('src', '/assets/icons/Unlink-Teal.png');
    // element.src = '/assets/Unlink-Teal.png';
    // }
    }
  }

  unlinkTeamUnhover() {
    if (this.allFieldsSaved && this.engagementStatus != 'Closed' && this.canManage){
    const elemUnlinkImg: Element = document.getElementById('imgUnlinkTeam');
      elemUnlinkImg.setAttribute('src', '/assets/icons/Unlink-Grey.png');
    // element.src = '/assets/Unlink-Grey.png';
    }
  }

    //End - Collaboration

    //private isAllRequiredFieldsPopulatedForTeams() {
    //    return !(this.shells[this.selectedEngagement].clientId == null
    //        || this.shells[this.selectedEngagement].name == null
    //        || this.shells[this.selectedEngagement].engagementManagerId == null
    //        || this.shells[this.selectedEngagement].engagementManagingDirectorId == null
    //        || this.shells[this.selectedEngagement].departmentId == null);
    //}


    //Start - PKIC Section

    createPKIC() {
        if (this.canCreatePKICRequest) {
          this.assistantService.createPKICRequest();
        }
    }

    createCDR() {
        if (this.canCreatePKICRequest) {
          this.assistantService.createCDR();
        }
    }

    pkicHover() {
        if (this.canCreatePKICRequest) {
          let elemImg: Element = document.getElementById('imgCreatePKIC');
          elemImg.setAttribute('src', '/assets/icons/New-Teal.png');

          let elemSpan: Element = document.getElementById('spanCreatePKIC');
          (elemSpan as HTMLElement).style.color = '#fff';

          (elemImg as HTMLElement).style.cursor = 'pointer';
          (elemSpan as HTMLElement).style.cursor = 'pointer';
        }
    }

    pkicUnHover(){
        if (this.canCreatePKICRequest) {
            let elemImg:Element = document.getElementById('imgCreatePKIC');
          elemImg.setAttribute('src','/assets/icons/New-Grey.png');

            let elemSpan:Element = document.getElementById('spanCreatePKIC');
            (elemSpan as HTMLElement).style.color ='#9d9d9d';
        }
    }

    cdrHover() {
        if (this.canCreatePKICRequest) {
            let elemImg:Element = document.getElementById('imgCreateCDR');
          elemImg.setAttribute('src','/assets/icons/New-Teal.png');

            let elemSpan:Element = document.getElementById('spanCreateCDR');
            (elemSpan as HTMLElement).style.color ='#fff';

            (elemImg as HTMLElement).style.cursor = 'pointer';
            (elemSpan as HTMLElement).style.cursor = 'pointer';
        }
    }

    cdrUnHover(){
        if (this.canCreatePKICRequest) {
            let elemImg:Element = document.getElementById('imgCreateCDR');
          elemImg.setAttribute('src','/assets/icons/New-Grey.png');

            let elemSpan:Element = document.getElementById('spanCreateCDR');
            (elemSpan as HTMLElement).style.color ='#9d9d9d';
        }
    }

    //End - PKIC Section

  //start DRB Section

  linkDRB() {
    if (this.canManage) {
      window.open(this.drbUrl);
    }
  }

  linkDRBHover() {
    if (this.canManage) {
      const elemImg: Element = document.getElementById('imgCreateDRB');
      elemImg.setAttribute('src', '/assets/icons/Plus-Edit-Teal.png');

      const elemSpan: Element = document.getElementById('spanCreateDRB');
      (elemSpan as HTMLElement).style.color = '#fff';

      (elemImg as HTMLElement).style.cursor = 'pointer';
      (elemSpan as HTMLElement).style.cursor = 'pointer';
    }
  }

  linkDRBUnhover() {
    if (this.canManage) {
      const elemImg: Element = document.getElementById('imgCreateDRB');
      elemImg.setAttribute('src', '/assets/icons/Plus-Edit-Gray.png');

      const elemSpan: Element = document.getElementById('spanCreateDRB');
      (elemSpan as HTMLElement).style.color = '#9d9d9d';
    }
    }

    createDRBHover(element: any) {
      if (this.canManage) {
        const elemImg: Element = document.getElementById('imgCreateDRB');
        elemImg.setAttribute('src', '/assets/icons/New-Teal.png');

        const elemSpan: Element = document.getElementById('spnCreateDRB');
        (elemSpan as HTMLElement).style.color = '#fff';

        (elemImg as HTMLElement).style.cursor = 'pointer';
        (elemSpan as HTMLElement).style.cursor = 'pointer';
      }
    }

    createDRBUnhover(element: any) {
      if (this.canManage) {
        const elemImg: Element = document.getElementById('imgCreateDRB');
        elemImg.setAttribute('src', '/assets/icons/New-Grey.png');

        const elemSpan: Element = document.getElementById('spnCreateDRB');
        (elemSpan as HTMLElement).style.color = '#9d9d9d';
      }
    }

  //End DRB Section

    openPowerBI(projectCode: string) {
        window.open(this.powerBIUrl + projectCode + '%27', 'popup', 'width=1280,height=720'); return false;
    }

    loadProposals(id: string) {//id - containerid
        this.proposalDocuments = [];
        this.assistantService.getProposalDocuments(id)
            .subscribe((list: any) => { this.proposalDocuments = list; });
    }

    uploadProposals() {
        if (this.canManage) {
            let proposalUploadUrl = this.config.iManageUrl + 'engagements/shellEdit/' + this.selectedShellId + '?source=assistant&isOpenUploadDocuments=1';
            //&source=assistant&navigationtype=redirect/popup
            if (this.config.isApp) { //inside iframe
                window.top.location.href = proposalUploadUrl + '&navtype=redirect';
            }
            else {
              if (window.top.location.href.indexOf('/engagements/shellLinkExisting/') != -1 || window.top.location.href.indexOf('/engagements/shellEdit/') != -1) {
                window.top.location.href = proposalUploadUrl + '&navtype=popup';
              }
              else {
                window.top.location.href = proposalUploadUrl + '&navtype=redirect';
              }
            }
        }
    }

    uploadProposalsHover(element: any) {
        if (this.allFieldsSaved && this.canManage) {
            const elemImg: Element = document.getElementById('imgCreateProposal');
          elemImg.setAttribute('src', '/assets/icons/New-Teal.png');

            const elemSpan: Element = document.getElementById('spnCreateProposal');
            (elemSpan as HTMLElement).style.color = '#fff';

            (elemImg as HTMLElement).style.cursor = 'pointer';
            (elemSpan as HTMLElement).style.cursor = 'pointer';
        }
    }

    uploadProposalsUnhover(element: any) {
        if (this.allFieldsSaved && this.canManage) {
            const elemImg: Element = document.getElementById('imgCreateProposal');
          elemImg.setAttribute('src', '/assets/icons/New-Grey.png');

            const elemSpan: Element = document.getElementById('spnCreateProposal');
            (elemSpan as HTMLElement).style.color = '#9d9d9d';
        }
    }

    deleteProposalDoc(name: string) {
        if (this.canManage) {
            var that = this;
            this.prompt = {
                notify: function (msg: any) {
                    this.msg = "";
                    if (msg == "ok") {
                        that.assistantService.deleteProposalDocument(name, that.selectedShellId).subscribe(response => {
                            that.loadProposals(that.selectedShellId);
                        });
                    }
                }, msg: "Click OK to delete or click Cancel."
            }
        }
    }

    deleteProposalHover(element: any) {
        if (this.canManage) {
          (element as HTMLElement).style.cursor = 'pointer';
          (element as HTMLElement).title = "Delete";
          //const elemImg: Element = document.getElementById('imgDeleteProposal');
          //elemImg.setAttribute('src', '/assets/Trash-Enabled.png');
          (element as HTMLElement).setAttribute('src', '/assets/icons/Trash-Enabled.png');
        }
    }

    deleteProposalUnhover(element: any) {
      if (this.canManage) {
        //const elemImg: Element = document.getElementById('imgDeleteProposal');
        //elemImg.setAttribute('src', '/assets/Trash-Disabled.png');
        (element as HTMLElement).setAttribute('src', '/assets/icons/Trash-Disabled.png');
      }
    }

    downloadProposalDoc(id: string, name: string) {
        //this.assistantService.downloadProposalDocument(id);
        //if (this.canManage) {
            this.assistantService.downloadFileService(this.selectedShellId, name).subscribe((data) => {
                this.blob = new Blob([data], { type: 'application/octet-stream' });
                var downloadUrl = window.URL.createObjectURL(data);
                var link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            });
        //}
    }

    proposalsDocHover(element: any) {
        //if (this.canManage) {
            (element as HTMLElement).style.cursor = 'pointer';
            (element as HTMLElement).style.color = '#fff';
            //(element as HTMLElement).style.textDecoration = 'underline';
            
        //}
    }

    proposalsDocUnhover(element: any) {
        //if (this.canManage) {
            (element as HTMLElement).style.color = '#9d9d9d';
            (element as HTMLElement).style.textDecoration = 'none';
        //}
  }

  populateContracts() {
    if (this.shells.length > 0 && typeof this.shells[this.selectedEngagement] != "undefined" && this.shells[this.selectedEngagement].isContractEnabled && !this.isTalentManager) {
      this.contractsUrl = this.config.iManageUrl + 'engagements/' + this.shells[this.selectedEngagement].id + '/contracts';
      this.showContractSection = true;
    }
    else {
      this.showContractSection = false;
    }
  }

  //Start - Resource Request
  populateResourceReq() {
    if (this.shells.length > 0 && typeof this.shells[this.selectedEngagement] != "undefined" && this.shells[this.selectedEngagement].isResourceRequestEnabled) {
      this.showResourceRequest = true;
      this.resReqUrl = this.config.iManageUrl + 'engagements/' + this.shells[this.selectedEngagement].id + '/resourcerequests';
    }
    else {
      this.showResourceRequest = false;
    }
  }

  createResReq() {
    if ((this.canManage || this.isTalentManager) && this.engagementStatus != 'Closed') {
      this.assistantService.createResReq();
    }
  }

  createResReqHover(element: any) {
    //if (this.engagementStatus != 'Closed' && this.allFieldsSaved) {
    //    element.src = '/assets/New-Teal.png';
    //}
    if ((this.canManage || this.isTalentManager) && this.engagementStatus != 'Closed') {
      const elemImg: Element = document.getElementById('imgCreateResReq');
      elemImg.setAttribute('src', '/assets/icons/New-Teal.png');

      const elemSpan: Element = document.getElementById('spnCreateResReq');
      (elemSpan as HTMLElement).style.color = '#fff';

      (elemImg as HTMLElement).style.cursor = 'pointer';
      (elemSpan as HTMLElement).style.cursor = 'pointer';
    }
    else {
      (element as HTMLElement).title = "";
    }
  }

  createResReqUnhover(element: any) {
    //element.src = '/assets/New-Grey.png';
    if ((this.canManage || this.isTalentManager) && this.engagementStatus != 'Closed') {
      const elemImg: Element = document.getElementById('imgCreateResReq');
      elemImg.setAttribute('src', '/assets/icons/New-Grey.png');

      const elemSpan: Element = document.getElementById('spnCreateResReq');
      (elemSpan as HTMLElement).style.color = '#9d9d9d';
    }
  }

  //End - Resource Request

  //start ClientAcceptance Section

  loadClientAcceptance() {
    this.clientAcceptanceWorkflows = [];
    this.showClientAcceptanceButton = false;
    this.isClientAcceptanceApprovalRequired = false;

    if (this.shells[this.selectedEngagement]) {
     this.isClientAcceptanceApprovalRequired = this.shells[this.selectedEngagement].isClientAcceptanceApprovalRequired;
    //  if (this.shells[this.selectedEngagement].isClientAcceptanceApprovalRequired && this.canManage) {
    //    this.showClientAcceptanceButton = true;
    //  }
    }

    if (this.allFieldsSaved && this.shells[this.selectedEngagement].type === EngagementType.OpportunityCreated) {
      this.showClientAcceptanceSection = this.type == "embedded" ? this.shells[this.selectedEngagement].isClientAcceptanceWorkflowEnalbed && this.shells[this.selectedEngagement].client.isClientAcceptanceApprovalRequired : this.shells[this.selectedEngagement].isClientAcceptanceWorkflowEnalbed;
      this.clientAcceptanceUrl = this.config.iManageUrl + 'engagements/' + this.selectedShellId + '/clientacceptance';

      if (this.shells[this.selectedEngagement].isClientAcceptanceApprovalRequired && this.canManage) {
        this.showClientAcceptanceButton = true;
      }

      //if (this.shells[this.selectedEngagement].clientAcceptanceWorkflows != null && this.shells[this.selectedEngagement].clientAcceptanceWorkflows.length > 0 && this.allFieldsSaved) {
      //  this.clientAcceptanceWorkflows = this.shells[this.selectedEngagement].clientAcceptanceWorkflows;
      //  for (var i = 0; i < this.clientAcceptanceWorkflows.length; i++) {
      //    this.clientAcceptanceWorkflows[i].creatdDate = Intl.DateTimeFormat(navigator.language, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(this.clientAcceptanceWorkflows[i].creatdDate));
      //  }
      //}
      
      this.assistantService.getClientAcceptanceWorkflows(this.shells[this.selectedEngagement].id).subscribe(res => {
        this.clientAcceptanceWorkflows = res;
        if (this.clientAcceptanceWorkflows != null && this.clientAcceptanceWorkflows.length > 0 && this.allFieldsSaved) {
            for (var i = 0; i < this.clientAcceptanceWorkflows.length; i++) {
              this.clientAcceptanceWorkflows[i].creatdDate = Intl.DateTimeFormat(navigator.language, { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(this.clientAcceptanceWorkflows[i].creatdDate));
            }
        }
      });

    }
    else {
      this.showClientAcceptanceSection = false;
    }
    console.log(this.showClientAcceptanceSection, this.showClientAcceptanceButton, this.isTalentManager, this.allFieldsSaved, this.isClientAcceptanceApprovalRequired, this.clientAcceptanceWorkflows.length > 0)
  }

  linkClientAcceptance() {
    if (this.canManage) {
      this.assistantService.OpenClientAcceptance(this.shells[this.selectedEngagement].id);
    }
  }

  linkClientAcceptanceHover() {
    if (this.canManage) {
      const elemImg: Element = document.getElementById('imgCreateClientAcceptance');
      elemImg.setAttribute('src', '/assets/icons/Plus-Edit-Teal.png');

      const elemSpan: Element = document.getElementById('spanCreateClientAcceptance');
      (elemSpan as HTMLElement).style.color = '#fff';

      (elemImg as HTMLElement).style.cursor = 'pointer';
      (elemSpan as HTMLElement).style.cursor = 'pointer';
    }
  }

  linkClientAcceptanceUnhover() {
    if (this.canManage) {
      const elemImg: Element = document.getElementById('imgCreateClientAcceptance');
      elemImg.setAttribute('src', '/assets/icons/Plus-Edit-Gray.png');

      const elemSpan: Element = document.getElementById('spanCreateClientAcceptance');
      (elemSpan as HTMLElement).style.color = '#9d9d9d';
    }
  }

  createClientAcceptanceHover(element: any) {
    if (this.showClientAcceptanceButton) {
      const elemImg: Element = document.getElementById('imgCreateClientAcceptance');
      elemImg.setAttribute('src', '/assets/icons/New-Teal.png');

      const elemSpan: Element = document.getElementById('spnCreateClientAcceptance');
      (elemSpan as HTMLElement).style.color = '#fff';

      (elemImg as HTMLElement).style.cursor = 'pointer';
      (elemSpan as HTMLElement).style.cursor = 'pointer';
    }
  }

  createClientAcceptanceUnhover(element: any) {
      const elemImg: Element = document.getElementById('imgCreateClientAcceptance');
    elemImg.setAttribute('src', '/assets/icons/New-Grey.png');

      const elemSpan: Element = document.getElementById('spnCreateClientAcceptance');
      (elemSpan as HTMLElement).style.color = '#9d9d9d';
  }

  createClientAcceptance() {
    if (this.showClientAcceptanceButton) {
      window.top.location.href = this.config.iManageUrl + 'engagements/' + this.selectedShellId + '/clientacceptance/0';
    }
  }

  //End ClientAcceptance Section
}
