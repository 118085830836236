
import {throwError as observableThrowError,  Observable, Subject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { map, tap, filter, switchMap ,  catchError } from 'rxjs/operators';
import { IShell } from './models/ishell';
import { ShellList } from './models/shell-list';
import { IRiskAssessment } from './models/irisk-assessment';
import { AdalAuthService } from './adal-auth.service';
import { AssistantConfig } from './assistant.config';
import { ConfigService } from './config.service';



@Injectable({
  providedIn: 'root'
})
export class LibImanageAssistantService {

  private headers: HttpHeaders;
  ERAFilterUrl = '';
  ERAUrl = '';

  currentUrl = '';
  type: string;
  public getShellUrl = 'Engagements';
  public currentShell = <IShell>{};
  public currentShells = [<IShell>{}];
  public iBudgetUrl: string;
  public iManageUrl: string;
  public jwtapiurl: string;
  assistantChange: Subject<any> = new Subject<any>();
  allFieldsSavedChange: Subject<boolean> = new Subject<boolean>();
  accessToken: string;
  public salesforceUrlPrefix: string = '';
  public salesforceLightningUrl: string = '';
  public engagementType: number = 1;

  constructor(@Inject('assistantConfig') private config: AssistantConfig, private adalAuthService: AdalAuthService,
    private http: HttpClient, private configService: ConfigService) {

    if (this.config.isApp) {
      this.iManageUrl = this.config.iManageUrl;
      this.iBudgetUrl = this.config.iBudgetUrl;
      this.jwtapiurl = this.config.iManageApiUrl + 'jwtapi/';
      this.ERAUrl = this.config.eraUrl;
      this.salesforceUrlPrefix = this.config.salesforceUrl;
      this.salesforceLightningUrl = this.config.salesforceLightningUrl;
    }
    else {
      this.configService.getConfig().subscribe(x => {
        this.iManageUrl = x.iManageUrl;
        this.iBudgetUrl = x.iBudgetUrl;
        this.jwtapiurl = x.iManageApiUrl + 'jwtapi/';
        this.ERAUrl = x.eraUrl;
        this.salesforceUrlPrefix = x.validUrl;
        this.salesforceLightningUrl = x.salesforceLightningUrl;
      });
    }
    

    this.accessToken = this.adalAuthService.getCachedToken();
    this.setHeaders();
  }

  setHeaders() {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
    this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers = this.headers.append('Accept', 'application/json');
    this.headers = this.headers.append('Cache-control', 'no-cache');
    this.headers = this.headers.append('Expires', '0');
    this.headers = this.headers.append('Pragma', 'no-cache');
    if (this.config.isApp) {
      this.headers = this.headers.append('authorization', `Bearer ${this.accessToken}`);
    }
  }

  getShellsAssistant(id: string, type: string): Observable<ShellList> {
    let url = this.jwtapiurl + this.getShellUrl + '/entity?entityId=' + id + '&type=' + type + '&pageSize=500';
    console.log('getShellsAssistant id ' + url);
    return this.http.get(url, { withCredentials: true, headers: this.headers })
      .pipe(map(this.extractDataToShellObject2));
  }

  getBudgets(id: string) {
    let url = this.jwtapiurl + this.getShellUrl + '/' + id + '/budgets';
    return this.http.get(url, { withCredentials: true, headers: this.headers })
      .pipe(
        map((response: HttpResponse<any>) => <any[]><any>response),
        catchError(this.handleError));
  }
  getChangeOrderOpprs(id: string) {
    let url = this.jwtapiurl + this.getShellUrl + '/' + id + '/changeorderopportunities';
    return this.http.get(url, { withCredentials: true, headers: this.headers })
      .pipe(
        map((response: HttpResponse<any>) => <any[]><any>response),
        catchError(this.handleError));
  }

  getClientAcceptanceWorkflows(id: string) {
    let url = this.jwtapiurl + this.getShellUrl + '/' + id + '/clientacceptanceworkflows';
    return this.http.get(url, { withCredentials: true, headers: this.headers })
      .pipe(
        map((response: HttpResponse<any>) => <any[]><any>response),
        catchError(this.handleError));
  }

  //private extractDataToShellObject2(response: Response): ShellList{
  private extractDataToShellObject2 = (response: Response): ShellList => {

    console.log("inside extractDataToShellObject2")
    let body = (Object)(response);
    let results: IShell[] = [];

    for (var i = 0; i < body.list.length; i++) {
      var result = <IShell>{};  // create an empty object of an interface

      result.id = body.list[i].id;
      if (body.list[i].type == 1) {
        result.shellUrl = this.iManageUrl + 'engagements/shellEdit/' + body.list[i].id;
      }
      else {
        result.shellUrl = this.iManageUrl + 'engagements/shellLinkExisting/' + body.list[i].id;
      }

      result.drbStatus = body.list[i].drbStatus ? body.list[i].drbStatus : null;
      result.DRBURL = body.list[i].drburl ? body.list[i].drburl : null;
      console.log("drbstatus: " + body.list[i].drbStatus + " drburl: " + body.list[i].drburl);
      
      result.name = body.list[i].name;
      result.description = body.list[i].description;
      result.clientId = body.list[i].clientId;
      result.clientName = body.list[i].client ? body.list[i].client.name : '';
      result.clientNameSalesForceUrl = body.accountUrl ? body.accountUrl : '';
      result.businessUnitId = body.list[i].businessUnitId;
      result.departmentId = body.list[i].departmentId;
      result.engagementManagingDirectorId = body.list[i].engagementManagingDirectorId;
      result.engagementManagerId = body.list[i].engagementManagerId;
      result.salesforceUrl = body.list[i].client ? body.list[i].client.salesforceUrl : '';
      result.serviceOfferingId = body.list[i].operationUnit ? body.list[i].operationUnit.solutionSegments[0].serviceOfferings[0].id : '';
      result.projectCode = body.list[i].projectCode;
      result.opportunityURL = body.list[i].opportunityUrl;
      result.canCreateOpportunity = body.list[i].canCreateOpportunity;
      result.type = body.list[i].type;
      this.engagementType = body.list[i].type;
      result.opportunityStatus = body.list[i].opportunityStatus;
      result.hasOneToManyLinkedTeamSite = body.list[i].hasOneToManyLinkedTeamSite;
      //result.IsTeamsPopUpEnabled = body.list[i].IsTeamsPopUpEnabled;
      
      result.teamMessage = body.list[i].teamMessage ? body.list[i].teamMessage : '';
      result.teamStatus = body.list[i].teamStatus ? body.list[i].teamStatus : 0;
      result.teamUrl = body.list[i].teamUrl ? body.list[i].teamUrl : '';
      result.teamSiteOption = body.list[i].teamSiteOption ? body.list[i].teamSiteOption : 0;

      result.engagementManagingDirectorIsDeleted = body.list[i].engagementManagingDirector ? body.list[i].engagementManagingDirector.isDeleted : false;
      result.engagementManagerIsDeleted = body.list[i].engagementManager ? body.list[i].engagementManager.isDeleted : false;
      result.OpportunityManagingDirectorId = body.list[i].opportunityManagingDirectorId;
      result.OpportunityManagingDirectorName = body.list[i].opportunityManagingDirector ? body.list[i].opportunityManagingDirector.name : '';
      result.OpportunityManagingDirectorIsDeleted = body.list[i].opportunityManagingDirector ? body.list[i].opportunityManagingDirector.isDeleted : false;
      result.opportunityOwnerId = body.list[i].opportunityOwnerId ? body.list[i].opportunityOwnerId : '';
      result.opportunityOwnerName = body.list[i].opportunityOwner ? body.list[i].opportunityOwner.name : '';
      result.opportunityOwnerIsDeleted = body.list[i].opportunityOwner ? body.list[i].opportunityOwner.isDeleted : false;

      if (body.list[i].businessUnit) {
        result.isInBudgetTool = body.list[i].businessUnit.isInBudgetTool;
        result.isTeamsEnabled = body.list[i].businessUnit.isTeamsEnabled;
        result.isContractEnabled = body.list[i].businessUnit.isContractEnabled;
      }

      result.isClientAcceptanceWorkflowEnalbed =
        (body.list[i].businessUnit ? body.list[i].businessUnit.isClientAcceptanceWorkflowEnalbed : false) && (body.list[i].client ? body.list[i].client.isClientAcceptanceApprovalRequired : false);


      if (body.list[i].riskAssessment) {
        result.riskAssessment = <IRiskAssessment>{};
        result.riskAssessment.url = body.list[i].riskAssessment ? body.list[i].riskAssessment.url : null;
        result.riskAssessment.name = body.list[i].riskAssessment ? body.list[i].riskAssessment.name : null;
        result.riskAssessment.opportunityUrl = body.list[i].opportunityUrl ? body.opportunityUrl : null;  // “Salesforce Opportunity” in EM Checklist 
        result.riskAssessmentUrl = result.riskAssessment.url;   // riskAssessmentUrl could be from SF text box or iManage created ERA
      }
      else {  // from SalesForce
        result.riskAssessmentUrl = body.list[i].riskAssessmentUrl ? body.list[i].riskAssessmentUrl : null;
      }

      result.changeorderopportunities = [];
      if (body.list[i].changeOrderOpportunities) {
        for (var j = 0; j < body.list[i].changeOrderOpportunities.length; j++) {
          result.changeorderopportunities.push(body.list[i].changeOrderOpportunities[j]);
        }
      }
      
      result.budgets = [];
      if (body.list[i].budgets) {
        for (var j = 0; j < body.list[i].budgets.length; j++) {
          if (body.list[i].budgets[j].isActivated == true) {
            result.hasActivatedSubBudget = true;
          }
          result.budgets.push(body.list[i].budgets[j]);
        }
      }
      result.budgetStatus = body.list[i].budgetStatus;

      result.IaNaturalOfWork = body.list[i].iaNaturalOfWork;
      //result.TransactionService = body.list[i].transactionService;
      //result.isDigital = body.list[i].isDigital;
      result.isManagedService = body.list[i].isManagedService;
      result.isConfidential = body.list[i].isConfidential;
      result.canManage = body.list[i].canManage;  // this is hack; store the global canManage property in each shell
      result.canUpdate = body.canUpdate;
      //result.canDelete = body.list[i].canDelete;
      result.canUnlinkEra = body.list[i].canUnlinkEra;
      result.canCreatePKICRequest = body.list[i].canCreatePKICRequest;
      result.status = body.list[i].status;
      result.canCreateMain = body.list[i].canCreateMain;
      result.leadSourceId = body.list[i].leadSourceId;
      result.serviceAmount = body.list[i].serviceAmount;
      result.opportunityStageId = body.list[i].opportunityStageId;
      result.opportunityCloseDate = body.list[i].opportunityCloseDate;
      result.closedDate = body.list[i].closedDate;
      result.probability = body.list[i].probability;
      result.userRole = body.list[i].userRole;
      result.ecoSystem = body.list[i].ecoSystem ? body.list[i].ecoSystem : '';
      result.opportunityPrimaryKeyBuyerId = body.list[i].opportunityPrimaryKeyBuyerId ? body.list[i].opportunityPrimaryKeyBuyerId : null;
      result.isClientAcceptanceApprovalRequired = body.list[i].isClientAcceptanceApprovalRequired;
      result.isResourceRequestEnabled = body.list[i].businessUnit ? body.list[i].businessUnit.isResourceRequestEnabled : false;
      

      if (body.list[i].client != null) {
        result.accountId = body.list[i].client.accountId ? body.list[i].client.accountId : '';
        result.accountKey = body.list[i].client.accountKey ? body.list[i].client.accountKey : null;
      } else {
        result.accountId = '';
        result.accountKey = null;
      }

      if (result.clientId
        && result.serviceOfferingId
        && result.description
        && result.IaNaturalOfWork
        && result.ecoSystem
        //&& (result.isDigital != null)
        && (result.isManagedService != null)) {
        if (result.type == 0) {
          if (result.opportunityURL && result.opportunityURL.length > 0 && (result.opportunityURL.toLowerCase().startsWith(this.salesforceUrlPrefix.toLowerCase() + '006') || result.opportunityURL.toLowerCase().startsWith(this.salesforceLightningUrl.toLowerCase() + 'r/opportunity/006'))
              && result.engagementManagerIsDeleted !== true && result.engagementManagingDirectorIsDeleted !== true) {
            result.allFieldsSaved = true;
          }
          else {
            result.allFieldsSaved = false;
            result.canCreateOpportunity = false;
            result.canCreatePKICRequest = false;
            result.canCreateMain = false;
          }
        }
        else if (result.type == 1) {
          if (result.name != null && result.name != "" && result.leadSourceId != null
              && result.opportunityStageId != null && result.opportunityStageId != "" && result.opportunityCloseDate != null && result.opportunityPrimaryKeyBuyerId != null
              && result.OpportunityManagingDirectorIsDeleted !== true && result.engagementManagerIsDeleted !== true && result.opportunityOwnerIsDeleted !== true && result.engagementManagingDirectorIsDeleted !== true) {
            result.allFieldsSaved = true;
          } else {
            result.allFieldsSaved = false;
            result.canCreateOpportunity = false;
            result.canCreatePKICRequest = false;
            result.canCreateMain = false;
          }
        }
      }
      else {
        result.allFieldsSaved = false;
      }
      result.clientAcceptanceWorkflows = [];
      if (body.list[i].clientAcceptanceWorkflows) {
        for (var j = 0; j < body.list[i].clientAcceptanceWorkflows.length; j++) {
          result.clientAcceptanceWorkflows.push(body.list[i].clientAcceptanceWorkflows[j]);
        }
      }

      this.currentShell = <IShell>{};  // this needed, otherwise error that currentShell is null
      this.currentShell = result;

      results.push(result);
    }
    let list = new ShellList();
    list.canAccessBudgetTool = body.canAccessBudgetTool;
    list.accountUrl = body.accountUrl;
    list.opportunityUrl = body.opportunityUrl;
    list.shells = results;
    return list;
  }

  // Start - ERA

  createERA() {
    window.open(this.ERAUrl + '?erasetup=1&clientId=' + this.currentShell.clientId + '&em=' + this.currentShell.engagementManagerId + '&emd=' + this.currentShell.engagementManagingDirectorId + '&shellId=' + this.currentShell.id + '&eraName=' + encodeURIComponent(this.currentShell.name) + '&isConfidential=' + this.currentShell.isConfidential, '_top');
  }

  createPKICRequest() {
    window.open(this.iManageUrl + 'pkicrequestform/' + this.currentShell.id + '?projectcode=' + this.currentShell.projectCode + '&name=' + encodeURIComponent(this.currentShell.name), '_blank');//?projectcode=
  }

  createCDR() {
    window.open(this.iManageUrl + 'pkiccdr/' + this.currentShell.id + '?clientid=' + this.currentShell.clientId + '&name=' + encodeURIComponent(this.currentShell.name), '_blank');//?projectcode=
  }

  OpenClientAcceptance(engagementId: string) {
    window.open(this.iManageUrl + 'engagements/clientacceptance/' + engagementId, '_top');
  }

  // linkERA(redirectUrl?: string) {
  //   if (redirectUrl) {
  //       console.log('shell.service.linkERA:redirectUrl' + redirectUrl);
  //       window.open(this.iManageUrl + 'engagements/linkfilter?shellId=' + this.currentShell.id + '&redirectUrl=' + encodeURIComponent(redirectUrl), '_top');
  //   }
  //   else {  // inside iManage
  //       window.open(this.iManageUrl + 'engagements/linkfilter?shellId=' + this.currentShell.id, '_top');
  //   }  
  // }

  createResReq() {
    //window.open(this.iManageUrl + '/resourcerequests/' + this.currentShell.id, '_blank');//?projectcode=
    window.open(this.iManageUrl + 'engagements/' + this.currentShell.id + '/resourcerequest/new', '_blank');
  }

  unlinkERA(id: string): Observable<any> {
    console.log('unlinkera: ' + this.jwtapiurl + this.getShellUrl + '/' + id);
    //var that = this;
    return this.http
      .patch(this.jwtapiurl + this.getShellUrl + '/' + id, [
        // .patch('https://devimanage.protiviti.com/' + this.getShellUrl + '/' + id, [ 
        {
          "op": "replace",
          "path": "/RiskAssessmentId",
          "value": null
        },
        {
          "op": "replace",
          "path": "/RiskAssessmentUrl",
          "value": null
        }
      ], { withCredentials: true, headers: this.headers })
      .pipe(
      map(function (r: HttpResponse<any>) {
        return r;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpResponse<any>): Observable<any> {
    console.log('handleError:');
    console.log(error);
    return observableThrowError({ status: error.status, body: error.statusText, errMsg: error.body } || 'Server error');
  }

  goShell(id: string, engType: number) {
    if (engType == 1) {
      window.open(this.iManageUrl + 'engagements/shellEdit/' + id, '_parent');
    }
    else {
      window.open(this.iManageUrl + 'engagements/shellLinkExisting/' + id, '_parent');
    }
  }

  goEra(id: string, name: string) {
    window.open(this.ERAUrl + name + '/' + id, '_top');
  }

  // End - ERA

  createTeamSite(engagementId: string): Observable<any> {
    console.log('createTeamSite: ' + engagementId);

    return this.http.post(this.jwtapiurl + this.getShellUrl + '/' + engagementId + '/teams',
      null, { withCredentials: true, headers: this.headers });
  }

  unlinkTeamSite(id: string): Observable<any> {
    console.log('unlinkteamsite: ' + this.jwtapiurl + this.getShellUrl + '/' + id);
    //var that = this;
    return this.http
      .patch(this.jwtapiurl + this.getShellUrl + '/' + id, [
        // .patch('https://devimanage.protiviti.com/' + this.getShellUrl + '/' + id, [ 
        {
          "op": "replace",
          "path": "/TeamSiteId",
          "value": null
        }
        // {
        //   "op": "replace",
        //   "path": "/TeamSiteId",
        //   "value": null
        // }
      ], { withCredentials: true, headers: this.headers })
      .pipe(
        map(function (r: HttpResponse<any>) {
          return r;
        }),
        catchError(this.handleError)
      );
  }

  createMain(engagementId: string): Observable<any> {
    return this.http.post(this.jwtapiurl + this.getShellUrl + '/' + engagementId + '/mains',
      null, { withCredentials: true, headers: this.headers });
  }

  createDRB(shell: IShell): Observable<any> {
    console.log('create DRB for: ' + shell.id);
    shell.drbStatus = "PENDING";
    return this.http
      .put(this.iManageUrl + 'api/Engagements/' + shell.id, shell, { withCredentials: true, headers: this.headers })
      .pipe(
        map(function (r: HttpResponse<any>) {
          return r;
        }),
        catchError(this.handleError)
      );
    }

    getProposalDocuments(containerId: any): Observable<any> {
        let url = this.iManageUrl + 'api/UploadFiles/';
        //let url = this.jwtapiurl + 'api/UploadFiles/';
        let parameters: HttpParams = new HttpParams();
        parameters = parameters.set('filters', 'ContainerId eq ' + containerId);
        parameters = parameters.set('sort', 'name');
        return this.http.get(url, { withCredentials: true, headers: this.headers, params: parameters })
            .pipe(map(function (r: any) {
                return r.list.filter(x=>x.type == 3);
                //var json = r;
                //return {
                //    data: json
                //}
            }), catchError(this.handleError));
    }

    deleteProposalDocument(name: string, containerid: string) {
        let url = this.iManageUrl + 'api/UploadFiles';
        let parameters: HttpParams = new HttpParams();
        parameters = parameters.set('containerId', containerid);
        parameters = parameters.set('fileType', '3');
        parameters = parameters.set('deleteFileName', name);
        return this.http.delete(url, { withCredentials: true, headers: this.headers, params: parameters })
    }

    downloadFileService(containerId: any, downloadFileName): Observable<Blob> {
        console.log('container ' + containerId + ' file: ' + downloadFileName);
        let url = this.iManageUrl + 'api/UploadFiles/' + containerId + '/Proposal/' + downloadFileName;
        let headers = new HttpHeaders();
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Cache-control', 'no-cache');
        headers = headers.append('Expires', '0');
        headers = headers.append('Pragma', 'no-cache');
        return this.http.get(url, { withCredentials: true, headers: this.headers, responseType: 'blob' });
  }
}
