import { HttpHeaders, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  headers = new HttpHeaders();


  constructor(private http: HttpClient) {
  }

  setHeaders() {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Content-Type', 'application/json');
    this.headers = this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers = this.headers.append('Accept', 'application/json');
    this.headers = this.headers.append('Cache-control', 'no-cache');
    this.headers = this.headers.append('Expires', '0');
    this.headers = this.headers.append('Pragma', 'no-cache');
  }



  getConfig(): Observable<SystemConfig>  {
    var currentUrl = document.location.href;
    var index = currentUrl.indexOf('/engagements/shellEdit/');
    var baseUrl = currentUrl.substr(0, index);

    var configApiUrl = baseUrl + '/api/configurations';

    this.setHeaders();
    var config = this.http.get(configApiUrl, { withCredentials: true, headers: this.headers })
      .pipe(map(this.mapConfig));

   return config;
  }

  private mapConfig = (response: HttpResponse<any>): SystemConfig => {
    let body = (Object)(response);
    var systemConfig = body.data.system;
    var config = new SystemConfig();
    config.iBudgetUrl = systemConfig.iBudget.value;
    config.iManageUrl = systemConfig.iManageWeb.value;
    config.iManageApiUrl = systemConfig.iManageWebAPIServer.value;
    config.eraUrl = systemConfig.ERAServer.value;
    config.pkicViewRequestsUrl = systemConfig.pkicViewRequestsUrl.value;
    config.teamsUrl = systemConfig.teamsUrl.value;
    config.salesforceLightningUrl = systemConfig.salesforceLightningUrl.value;
    config.validUrl = systemConfig.validUrl.value;
    config.powerBIUrl = systemConfig.powerBIUrl.value;
    config.drbUrl = systemConfig.drbUrl.value;
    return config;
  }

}

export class SystemConfig {
  iBudgetUrl: string;
  iManageUrl: string;
  iManageApiUrl: string;
  eraUrl: string;
  pkicViewRequestsUrl: string;
  teamsUrl: string;
  validUrl: string;
  salesforceLightningUrl: string;
  powerBIUrl: string;
  drbUrl: string;
}
