import { Component } from '@angular/core';

import { LibImanageAssistantService } from 'projects/lib-imanage-assistant/src/public_api';

@Component({
  selector: 'imast-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'iManage Assistant';

  constructor(private service: LibImanageAssistantService) {
  }
}
