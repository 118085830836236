import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.css']
})
export class PromptComponent implements OnInit {

  @Input()
  public alertmsg = "";
  
  @Output()
  notify: EventEmitter<string> = new EventEmitter<string>();

	ngOnInit() {}

	clickOk(ok:string){
    this.notify.emit('ok');
  }

  clickCancel(cancel:string){
    this.notify.emit('cancel');
  }

}
