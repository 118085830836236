import { NgModule, ModuleWithProviders } from '@angular/core';
import { LibImanageAssistantComponent } from './lib-imanage-assistant.component';
import { AssistantConfig } from './assistant.config';
import { CommonModule } from '@angular/common';
import { AdalService, AdalGuard } from 'adal-angular4';
import { HttpClientModule } from '@angular/common/http';
import { PromptComponent } from './prompt/prompt.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [LibImanageAssistantComponent, PromptComponent],
  exports: [LibImanageAssistantComponent],
  providers: [AdalService, AdalGuard]
})
export class LibImanageAssistantModule {
  public static forRoot(assistantConfig: AssistantConfig): ModuleWithProviders<LibImanageAssistantModule> {
    return {
      ngModule: LibImanageAssistantModule,
      providers: [{provide: 'assistantConfig', useValue: assistantConfig}]
    };
  }
}
