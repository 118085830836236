export const environment = {
  production: false,
  isApp: true,
  appConfig: {
    iBudgetUrl: 'https://dev.ibudget-dev.protiviti.com/',
    iManageUrl: 'https://dev.imanage-dev.protiviti.com/',
    iManageApiUrl: 'https://dev.imanage-dev.protiviti.com/',
    eraUrl: 'https://dev.era-dev.protiviti.com/',
    salesforceUrl: 'https://protiviti--apptest.cs14.my.salesforce.com/',
    salesforceLightningUrl: 'https://protiviti--apptest.lightning.force.com/lightning/',
    powerBIUrl: 'https://app.powerbi.com/reportEmbed?reportId=2553d114-6706-499d-ba86-be0c60bd2525&appId=0e0c062d-8665-4834-bae1-c293fd9bbcd4&autoAuth=true&ctid=16532572-d567-4d67-8727-f12f7bb6aed3&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQifQ%3D%3D&filter=iManageTestTable%2FProject_x0020_Code%20eq%20%27',
    drbUrl: 'https://roberthalf.sharepoint.com/sites/PRO-DRB/SiteAssets/Load-DRB.aspx?drbid='
  },
  adal: {
    clientId : 'fc3806a1-626a-4297-b82b-7ffd3ab80164',
    tenant: '16532572-d567-4d67-8727-f12f7bb6aed3',
    instance: 'https://login.microsoftonline.com/'
  },
  pkicViewRequestsUrl : 'https://pkicrequest.ishare.protiviti.com/portal/SitePages/View_My_Requests.aspx',
  teamsUrl: 'https://teams.microsoft.com/'
};
