import { Injectable, Inject } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { interval } from 'rxjs';
import { AssistantConfig } from './assistant.config';

@Injectable({
  providedIn: 'root'
})
export class AdalAuthService {

  clientid:string;// = 'fc3806a1-626a-4297-b82b-7ffd3ab80164';
  tenant:string;// = '16532572-d567-4d67-8727-f12f7bb6aed3';
  instance:string;// = 'https://login.microsoftonline.com/';

  constructor(@Inject('assistantConfig') private config: AssistantConfig, private adalService: AdalService) {
    let isEdge = window.navigator.userAgent.toLowerCase().indexOf('edge') > -1;
    let currentUrl = window.location.search;
    var popupWindow = null;
    var parent = null;

    adalService.init({
      tenant: config.tenant,
      clientId: config.clientId,
      redirectUri: window.location.origin,
      instance: config.instance,
      popUp: true,
      cacheLocation: 'localStorage',
      navigateToLoginRequestUrl: false,
      displayCall:function (urlNavigate) {
        if(isEdge){
          this.sub = interval(5000)
            .subscribe((val) => { if(document.cookie.indexOf('iManageEdgeHelper') > -1){ this.sub.unsubscribe(); window.location.reload(); } });
        }

        popupWindow = window.open('', "login", 'width=483, height=600');
        popupWindow.location = urlNavigate;

        parent = popupWindow.opener;

        if (popupWindow && popupWindow.focus){
          popupWindow.focus();
        }
        var registeredRedirectUri = this.redirectUri;
        var pollTimer = window.setInterval(function () {
          if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
              window.clearInterval(pollTimer);
          }
          try {
              if (popupWindow.document.URL.indexOf(registeredRedirectUri) != -1) {
                  window.clearInterval(pollTimer);
                  window.location.hash = popupWindow.location.hash;
                  adalService.handleWindowCallback();
                  popupWindow.close();
                  //popupWindow.opener.location.reload();
                  parent.location.href = currentUrl;
                  //window.location.href=window.location.search;
              }
          } catch (e) {
          }
      }, 300);
       }
    });
  }

  login(){
    this.adalService.login();
  }

  logout(){
    this.adalService.logOut();
  }

  handlecallback(){
    this.adalService.handleWindowCallback();
  }

  aquireToken(){
    this.adalService.acquireToken(this.config.clientId);
  }

  getCachedToken(){
    return this.adalService.getCachedToken(this.config.clientId);
  }

  isUserAuthenticated(){
    return this.adalService.userInfo.authenticated;
  } 
}
