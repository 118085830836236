import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LibImanageAssistantModule } from 'projects/lib-imanage-assistant/src/public_api';
//import { LibImanageAssistantModule } from 'lib-imanage-assistant';
import { AdalService, AdalGuard } from 'adal-angular4';

import { environment } from './../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    LibImanageAssistantModule.forRoot({
      isApp: true,
      iBudgetUrl: environment.appConfig.iBudgetUrl,
      iManageUrl: environment.appConfig.iManageUrl,
      iManageApiUrl: environment.appConfig.iManageApiUrl,
      eraUrl: environment.appConfig.eraUrl,
      tenant: environment.adal.tenant,
      clientId: environment.adal.clientId,
      instance: environment.adal.instance,
      pkicViewRequestsUrl: environment.pkicViewRequestsUrl,
      teamsUrl: environment.teamsUrl,
      salesforceUrl: environment.appConfig.salesforceUrl,
      salesforceLightningUrl: environment.appConfig.salesforceLightningUrl,
      powerBIUrl: environment.appConfig.powerBIUrl,
      drbUrl: environment.appConfig.drbUrl
    })
  ],
  providers: [AdalService, AdalGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
